export const textStyles = {
  h1: {
    lineHeight: 'unset',
    size: {
      max: 72,
      min: 38,
      viewport: 2.8,
      multiplier: 1.3,

    },
    weight: 700,
  },
  h2: {
    lineHeight: 'unset',
    size: {
      max: 68,
      min: 28,
      viewport: 1.4,
      multiplier: 1.3,
    },
    weight: 700,
  },
  h3: {
    lineHeight: 1.3,
    size: {
      max: 26,
      min: 22,
      viewport: 0.45,
      multiplier: 1,
    },
    weight: 600,
  },
  h4: {
    lineHeight: 1,
    size: {
      max: 18,
      min: 16,
      viewport: 0.4,
      multiplier: 1,
    },
    weight: 400,
  },
  h5: {
    lineHeight: 1,
    size: {
      max: 16,
      min: 14,
      viewport: 0.4,
      multiplier: 0.63,
    },
    weight: 400,
  },
  h6: {
    lineHeight: 1,
    size: {
      max: 14,
      min: 12,
      viewport: 0.4,
      multiplier: 0.5,
    },
    weight: 400,
  },
  p1: {
    lineHeight: 'unset',
    size: {
      max: 20,
      min: 16,
      viewport: 0.3,
      multiplier: 0.44,

    },
    weight: 400,
  },
  p2: {
    lineHeight: 1,
    size: {
      max: 16,
      min: 14,
      viewport: 0.3,
      multiplier: 0.44,
    },
    weight: 400,
  },
  p3: {
    lineHeight: 1,
    size: {
      max: 12,
      min: 11,
      viewport: 0.3,
      multiplier: 0.44,
    },
    weight: 400,
  },
  p4: {
    lineHeight: 1,
    size: {
      max: 11,
      min: 10,
      viewport: 0.3,
      multiplier: 0.44,
    },
    weight: 400,
  },
}
