import { React, variantProvider, Settings, AppImages } from '@/app'
import { onUpdate, useCodeleapContext, useState } from '@codeleap/common'
import { Session } from '@/redux'
import { Image, Touchable } from '@/components'

type LogoProps = {
  variants?: string | string[]
  switchServerOnPress?: boolean
  style?: {}
}

export function Logo(props: LogoProps) {


  const source = props.variants?.includes('primary') ? AppImages.RedLogo : AppImages.WhiteLogo

  const [numberOfPresses, setPresses] = useState(0)

  onUpdate(() => {
    if (numberOfPresses === 10) {
      Session.setMode()
      setPresses(0)
    }
  }, [numberOfPresses])

  const image = (
    <Image source={`${source}`} css={[styles.image, props?.style]} />
  )

  if (props.switchServerOnPress) {
    return <Touchable onPress={() => setPresses(n => n + 1) }
      debugName={'Click on Logo'}>
      {image}
    </Touchable>
  }

  return image
}

const styles = variantProvider.createComponentStyle({
  image: {
    height: undefined,
    width: '75%',
    alignSelf: 'center',
  },
}, true)
