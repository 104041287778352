import { CenterWrapperComposition } from '@codeleap/common'
import { variantProvider, Theme } from '../theme'

const createCenterWrapperStyle =
  variantProvider.createVariantFactory<CenterWrapperComposition>()
const defaultStyles = variantProvider.getDefaultVariants('CenterWrapper')

export const AppCenterWrapperStyles = {
  ...defaultStyles,
  default: createCenterWrapperStyle((theme) => ({
    ...defaultStyles.default(theme),
    wrapper: {
      ...defaultStyles.default(theme).wrapper,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      width: '100%',

      [Theme.media.down('xxlarge')]: {
        paddingLeft: Theme.spacing.value(16),
        paddingRight: Theme.spacing.value(16),
      },
      [Theme.media.down('large')]: {
        paddingLeft: Theme.spacing.value(12),
        paddingRight: Theme.spacing.value(12),
      },
      [Theme.media.down('largeish')]: {
        paddingLeft: Theme.spacing.value(8),
        paddingRight: Theme.spacing.value(8),
      },
      [Theme.media.down('mid')]: {
        paddingLeft: Theme.spacing.value(4),
        paddingRight: Theme.spacing.value(4),
      },
      [Theme.media.down('small')]: {
        paddingLeft: Theme.spacing.value(2),
        paddingRight: Theme.spacing.value(2),
      },

    },
    innerWrapper: {
      ...defaultStyles.default(theme).wrapper,
      maxWidth: 1280,
      padding: 0,
      justifyContent: 'unset',
    },

  })),
}
