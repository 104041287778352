import { onMount, onUpdate, useCodeleapContext } from '@codeleap/common'
import { Provider as ReduxProvider } from 'react-redux'
import { React, LocalStorageKeys, Theme, variantProvider, AppThemeModes, Settings } from './app'
import { Session, store, useAppSelector } from './redux'
import { Global } from '@emotion/react'
import { globalStyleDark, globalStyleLight } from './app/stylesheets/Global'
import { ToastContainer } from 'react-toastify'

import { withFirebase } from './services/firebase'
import { useWindowSize } from '@codeleap/web'

const Toaster = () => {
  const { currentTheme } = useCodeleapContext()
  const colors = Theme.colors[currentTheme]
  const { isLoggedIn, appMounted } = useAppSelector(store => store.Session)

  onUpdate(() => {
    const unsubscribe = withFirebase((fb) => fb.auth().onAuthStateChanged((user) => {
      if (user && !isLoggedIn && !appMounted) {
        Session.autoLogin(false).then(() => Session.setMounted()).catch(() => Session.setMounted())
      } else {
        Session.setMounted()
      }
    }))

    return () => {
      unsubscribe.then(unsub => unsub())
    }
  }, [isLoggedIn])
  return <>
    <Global styles={currentTheme === 'dark' ? globalStyleDark : globalStyleLight}/>
    <ToastContainer
      toastStyle={{
        background: colors.background,
        color: colors.textH,
      }}
    />
  </>
}

const Root = ({ children }) => {
  const { currentTheme } = useCodeleapContext()

  const windowSize = useWindowSize()
  onMount(() => {
    const savedTheme = localStorage.getItem(LocalStorageKeys.THEME) as AppThemeModes
    if (savedTheme) {
      variantProvider.setColorScheme(savedTheme)
    }

    // const sessionMode = localStorage.getItem(LocalStorageKeys.SESSION_IS_DEV) as string
    // Session.setMode(sessionMode === 'true')
  })

  onUpdate(() => {
    localStorage.setItem(LocalStorageKeys.THEME, currentTheme as AppThemeModes)
  }, [currentTheme])
  variantProvider.theme.values.height = windowSize[1]
  variantProvider.theme.values.width = windowSize[0]
  return (

    <ReduxProvider store={store}>
      <Toaster />
      <Wrapper h={windowSize[1]} w={windowSize[0]}>
        {children}
      </Wrapper>
    </ReduxProvider>

  )
}

const Wrapper = ({ children, h, w }) => {

  variantProvider.theme.values.height = h
  variantProvider.theme.values.width = w

  return <>
    {children}
  </>
}
export default Root
