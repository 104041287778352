/* eslint-disable max-len */
import { AppImages } from '@/app'

const homeBackgrounds = [
  { imageHomeDesktop: AppImages.GreenBackground, imageMobile: AppImages.GreenBackgroundMobile, messages: [{ text: 'Kiss' }, { text: 'Fewer' }, { text: 'Frogs' }] },
  { imageHomeDesktop: AppImages.RedBackground, imageMobile: AppImages.RedBackgroundMobile, messages: [{ text: 'Kiss' }, { text: 'Fewer' }, { text: 'Frogs' }] },
  { imageHomeDesktop: AppImages.BlueBackground, imageMobile: AppImages.BlueBackgroundMobile, messages: [{ text: 'Goodbye' }, { text: 'Catfish' }] },
]

type Messages = {
  text: string
}

type HomeBackground = {
  imageHomeDesktop: keyof typeof AppImages
  imageMobile: keyof typeof AppImages
  messages: Messages[]
}

export type BackgroundDetails = {
  imageNameDesktop: keyof typeof AppImages
  imageNameMobile: keyof typeof AppImages
  messages: Messages[]
}

export const getRandomHomeImage = (): BackgroundDetails => {
  const randomIndex: number = Math.floor(Math.random() * homeBackgrounds.length)
  const selectedBackground: HomeBackground = homeBackgrounds[randomIndex]

  const imageNameDesktop: keyof typeof AppImages = selectedBackground.imageHomeDesktop
  const imageNameMobile: keyof typeof AppImages = selectedBackground.imageMobile
  const messages: any[] = selectedBackground.messages

  return {
    imageNameDesktop,
    imageNameMobile,
    messages: messages,
  }
}
