import { api, logger } from '@/app'
import { Toast } from '@codeleap/web'

export type CreateContactRequest = {
  email: string
  name: string
  message: string
}

export async function sendContactUs(data: CreateContactRequest) {

  try {
    const res = await api.post(`profiles/contact_us/`, data)

    Toast.success(
      { title: 'Message sent successfully!' },
    )

    return res

  } catch (err) {
    Toast.error({
      title: 'Error sending contact, please try again',
    })
    logger.error('Signup error', err)
    return null

  }
}
