import { TextComposition, TextStyles } from '@codeleap/common'
import { variantProvider } from '../theme'

const createTextStyle = variantProvider.createVariantFactory<TextComposition>()
const defaultStyles = TextStyles

export const AppTextStyles = {
  ...defaultStyles,
  default: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      ...defaultStyles.default(theme).text,

      textDecoration: 'none',
    },
  })),
  white: createTextStyle((theme) => ({
    text: {
      color: theme.colors.white,
    },
  })),
  black: createTextStyle((theme) => ({
    text: {
      // ...defaultStyles.default(theme).text,
      color: theme.colors.black,
    },
  })),
  black400: createTextStyle((theme) => ({
    text: {
      // ...defaultStyles.default(theme).text,
      color: theme.colors.black400,
    },
  })),
  black700: createTextStyle((theme) => ({
    text: {
      // ...defaultStyles.default(theme).text,
      color: theme.colors.black700,
    },
  })),
  black800: createTextStyle((theme) => ({
    text: {
      // ...defaultStyles.default(theme).text,
      color: theme.colors.black800,
    },
  })),
  red: createTextStyle((theme) => ({
    text: {
      color: 'red',
    },
  })),
  primary: createTextStyle((theme) => ({
    text: {
      color: theme.colors.primary,
    },
  })),
  listItem: createTextStyle((theme) => ({
    ...defaultStyles.default,
    text: {
      ...theme.spacing.padding(0.3),
      whiteSpace: 'nowrap',
    },
  })),
  bold: createTextStyle((theme) => ({
    text: {
      fontWeight: 'bold',
    },
  })),
  semiBold: createTextStyle((theme) => ({
    text: {
      fontWeight: '500',
    },
  })),
  thin: createTextStyle((theme) => ({
    text: {
      fontWeight: '300',
    },
  })),
  link: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      ...defaultStyles.default(theme).text,

      '&:hover': {
        color: theme.colors.primary,
      },
    },
  })),
  underlined: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      textDecoration: 'underline',
      '&:hover': {
        color: theme.colors.primary,
      },
    },
  })),
  uppercase: createTextStyle((theme) => ({
    ...defaultStyles.default(theme),
    text: {
      textTransform: 'uppercase',
    },
  })),

}
