/** @jsx jsx */
import { jsx } from '@emotion/react'
import { graphql } from 'gatsby'
import {
	ComponentVariants,
	ImageComposition,
	ImageStyles,
	StylesOf,
	useDefaultComponentStyle,
} from '@codeleap/common'
import { CSSProperties } from 'react'

// const dynamicAssetPrefixes = ['http', 'https', 'blob']

type ImageProps = {
	source: string
	css?: any
	alt?: string
	objectFit?: Exclude<CSSProperties['objectFit'], CSSProperties['objectFit'][]>
	styles?: StylesOf<ImageComposition>
	style?: any
} & ComponentVariants<typeof ImageStyles>

// export const Image: React.FC<ImageProps> = (imgProps) => {
//   const {
//     variants = [],
//     responsiveVariants = {},
//     styles,
//     objectFit,
//     req,
//     ...props
//   } = imgProps

//   if (!props.alt) {
//     logger.log('missing alt property in img', { source }, 'Component')
//   }

//   const alt = props.alt || 'image'

//   const isStatic =
//     !props.img &&
//     !req &&
//     dynamicAssetPrefixes.filter((i) => source?.startsWith(i)).length < 1

//   if (isStatic) {
//     const image = getImage(fileNode)
//     logger.log('isStatic', { props, source, image }, 'Component')
//     return (
//       <GatsbyImage
//         {...props}
//         objectFit={objectFit}
//         image={image}
//         css={variantStyles.wrapper}
//         alt={alt}
//       />
//     )
//   }

//   logger.log('not static', { props, source }, 'Component')
//   return (
//     <img
//       {...props}
//       style={{ objectFit }}
//       src={source}
//       css={variantStyles.wrapper}
//     />
//   )
// }


export const Image: React.FC<ImageProps> = (imageProps) => {
	const {
		source,
		variants,
		responsiveVariants,
		styles,
		objectFit,
		style,
		...props
	} = imageProps

	const variantStyles = useDefaultComponentStyle('Image', {
		responsiveVariants,
		variants,
		styles,
	})

	return (
		<img
			{...props}
			style={{ objectFit }}
			src={source}
			css={[variantStyles.wrapper, style]}
		/>
	)
}

const query = graphql`
	query {
		allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
			nodes {
				childImageSharp {
					gatsbyImageData
				}
				relativePath
			}
		}
	}
`
