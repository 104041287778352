import { DrawerComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createDrawerStyle =
  variantProvider.createVariantFactory<DrawerComposition>()
const defaultStyles = variantProvider.getDefaultVariants('Drawer')

export const AppDrawerStyles = {
  ...defaultStyles,
  default: createDrawerStyle((theme) => ({
    ...defaultStyles.default,
  })),
  fullScreen: createDrawerStyle((theme) => ({
    ...defaultStyles.default,
    wrapper: {
      ...theme.presets.fullWidth,
      // ...theme.presets.fullViewHeight,
      height: '100vh',
    },
    body: {
      // ...defaultVariant(theme).wrapper,
      ...theme.presets.fullWidth,
      // ...theme.presets.fullViewHeight,
      height: '100vh',
    },
    box: {

      height: '100vh',
    },
    headerCloseButton: {
      color: 'red',
      backgroundColor: 'red',
    },
  })),
}
