import { TextInputComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

const createTextInputStyle =
	variantProvider.createVariantFactory<TextInputComposition>()
const defaultStyles = variantProvider.getDefaultVariants('TextInput')

const defaultVariant = createTextInputStyle((theme) => ({
	...defaultStyles.default(theme),
	wrapper: {
		...defaultStyles.default(theme).wrapper,
	},
	textField: {
		...defaultStyles.default(theme).textField,
		color: theme.colors.black700,
	},
	error: {
		...defaultStyles.default(theme).error,
		color: 'red',
	},
	innerWrapper: {
		...defaultStyles.default(theme).innerWrapper,
		borderRadius: theme.borderRadius.medium,
		borderColor: theme.colors.black200,
		height: 54,
	},
	icon: {
		...defaultStyles.default(theme).icon,
		color: theme.colors.neutral,
	},
	label: {
		...defaultStyles.default(theme).label,
		color: theme.colors.black,
		marginBottom: theme.spacing.value(2),
		fontWeight: 'bold',
	},
}))

const MULTILINE_TEXTINPUT = 120

export const AppTextInputStyles = {
	...defaultStyles,
	default: defaultVariant,
	multiline: createTextInputStyle((theme) => ({
		...defaultStyles.default(theme),
		innerWrapper: {
			...defaultStyles.default(theme).innerWrapper,
			height: MULTILINE_TEXTINPUT,
			alignItems: 'start',
			padding: theme.spacing.value(2),
		},
		label: {
			...defaultStyles.default(theme).label,
			color: theme.colors.black,
			marginBottom: theme.spacing.value(2),
			fontWeight: 'bold',
		},
		textField: {
			...defaultStyles.default(theme),
			height: MULTILINE_TEXTINPUT,
			color: theme.colors.black700,
		},
	})),
	'resize:none': createTextInputStyle((theme) => ({
		...defaultStyles.default(theme),
		textField: {
			...defaultStyles.default(theme).textField,
			resize: 'none',
			color: theme.colors.black700,
		},
		
	})),
}
