import * as Components from '@codeleap/web'
import { variantProvider } from '@/app/theme'

import { Image as _Image } from '@/components/Image'
import { Link as _Link } from '@/components/Link'

import * as StyleSheets from '@/app/stylesheets'

import { MyComponent as _MyComponent } from '../components/MyComponent'

import { Settings } from '@/app'
import { Avatar as _Avatar } from './Avatar'
const defaultStyles = variantProvider.getDefaultVariants()

export const variants = {
  ...defaultStyles,
  View: StyleSheets.AppViewStyles,
  Text: StyleSheets.AppTextStyles,
  TextInput: StyleSheets.AppTextInputStyles,
  RadioInput: StyleSheets.AppRadioInputStyles,
  Modal: StyleSheets.AppModalStyles,
  Button: StyleSheets.AppButtonStyle,
  Icon: StyleSheets.AppIconStyles,
  ActivityIndicator: StyleSheets.AppActivityIndicatorStyles,
  MobileSelect: StyleSheets.AppSelectStyles,
  MyComponent: StyleSheets.MyComponentStyle,
  RouterPage: StyleSheets.AppRouterPageStyles,
  CenterWrapper: StyleSheets.AppCenterWrapperStyles,
  Checkbox: StyleSheets.AppCheckboxStyle,
  Select: StyleSheets.AppSelectStyles,
}

export const components = variantProvider.typeComponents({
  View: [Components.View, StyleSheets.AppViewStyles],
  Icon: [Components.Icon, StyleSheets.AppIconStyles],
  Text: [Components.Text, StyleSheets.AppTextStyles],
  Touchable: [Components.Touchable, defaultStyles.Touchable],
  TextInput: [Components.TextInput, StyleSheets.AppTextInputStyles],
  // Switch: [Components.Switch, defaultStyles.Switch],
  Checkbox: [Components.Checkbox, defaultStyles.Checkbox],
  RadioInput: [Components.RadioGroup, defaultStyles.RadioInput],
  ContentView: [Components.ContentView, defaultStyles.ContentView],
  Select: [Components.Select, StyleSheets.AppSelectStyles],
  Slider: [Components.Slider, defaultStyles.Slider],
  FileInput: [Components.FileInput, defaultStyles.FileInput],
  Image: [_Image, defaultStyles.Image],
  Scroll: [Components.Scroll, defaultStyles.View],
  List: [Components.List, defaultStyles.View],
  ActivityIndicator: [
    Components.ActivityIndicator,
    defaultStyles.ActivityIndicator,
  ],
  CenterWrapper: [Components.CenterWrapper, StyleSheets.AppCenterWrapperStyles],
  Button: [Components.Button, StyleSheets.AppButtonStyle],
  Modal: [Components.Modal, StyleSheets.AppModalStyles],
  Link: [_Link, StyleSheets.AppTextStyles],
  // Pager: [Components.Pager, AppPagerStyle],
  Menu: [Components.Menu, {}],
  Tooltip: [Components.Tooltip, StyleSheets.AppTooltipStyles],
  RouterPage: [Components.RouterPage, StyleSheets.AppRouterPageStyles],
  MyComponent: [_MyComponent, StyleSheets.MyComponentStyle],
  Drawer: [Components.Drawer, StyleSheets.AppDrawerStyles],
  Overlay: [Components.Overlay, StyleSheets.AppOverlayStyles],
  Avatar: [_Avatar, StyleSheets.AvatarStyles],
  Collapse: [Components.Collapse, StyleSheets.AppViewStyles],
  MultiSelect: [Components.MultiSelect, StyleSheets.AppSelectStyles],
})

export const OSAlert = Components.CreateOSAlert({
  variantProvider,
  variants,
  settings: Settings,
  logger,
})

export const {
  View,
  Checkbox,
  Button,
  Text,
  Icon,
  FileInput,
  TextInput,
  Select,
  MyComponent,
  Touchable,
  Slider,
  CenterWrapper,
  Collapse,
  Image,
  Scroll,
  List,
  Link,
  Drawer,
  Overlay,
  ActivityIndicator,
  MultiSelect,
  RadioInput,
  ContentView,
  RouterPage,
  Menu,
  Tooltip,
  Modal,
  Avatar,
} = components

export * from './AppStatus'
export * from './Logo'
export * from './MyComponent'
export * from './Link'
export * from './Header'
export * from './Page'
export * from './RequiresAuth'
export * from './PostCard'
export * from './Footer'
export * from './Header'

export default components
