import { createTheme, VariantProvider } from '@codeleap/common'
import { textStyles } from './textStyles'
import { CSSObject } from '@emotion/css'
import { Icons } from './assets/icons'
import { useWindowSize } from '@codeleap/web'
import { AppStore, GooglePlay, Close, Mail, Heart, Facebook, Instagram, Bars } from './icons'

const IS_SSR = typeof window === 'undefined'

const getWindowDimensions = () => {
  if (IS_SSR) {
    return {
      height: 0,
      width: 0,
    }
  }
  return {
    width: window?.innerWidth || 0,
    height: window?.innerHeight || 0,
  }
}
const themeObj = {
  colors: {
    light: {
      primary: '#DF1C1C',
      primaryDarker: '#bf0f0f',
      icon: '#000',
      border: '#7695EC',
      disabled: '#a4aabc',
      text: '#000000',
      textPrimary: '#262535',
      background: '#fff',
      backgroundSecondary: '#fff',
      gray: '#333',
      lightGray: '#ccc',
      veryLightGray: '#f7f7f7',
      negative: '#a11',
      positive: '#ada',
      lightPrimary: '#FFD3CE',
      secondary: '#000',
      black: '#000',
      black200: '#CCCCCC',
      black300: '#B3B3B3',
      black400: '#999999',
      black700: '#4D4D4D',
      black800: '#333333',
      borders: '#ccc',
      placeholder: '#ccc',
      neutral: '#5f5f5f',
      white: '#fff',
      green: 'green',
      textH: '#333',
      textP: '#555',
      grayFade: '#5552',
    },
    dark: {
      primary: '#DF1C1C',
      primaryDarker: '#bf0f0f',
      lightPrimary: '#FFD3CE',
      icon: '#fff',
      border: '#7695EC',
      disabled: '#a4aabc',
      text: '#fff',
      textPrimary: '#262535',
      background: '#222222',
      backgroundSecondary: '#303030',
      gray: '#333',
      neutral: '#777777bb',
      lightGray: '#ccc',
      black: '#000',
      black200: '#CCCCCC',
      black300: '#B3B3B3',
      black400: '#999999',
      black700: '#4D4D4D',
      black800: '#333333',
      veryLightGray: '#f7f7f7',
      negative: '#a11',
      positive: '#ada',
      secondary: '#000',
      borders: '#ccc',
      placeholder: '#ccc',
      white: '#fff',
      green: 'green',
      textH: '#fff',
      textP: '#fff',
      grayFade: '#5552',
    },
  },
  breakpoints: {
    zero: 0,
    tinyest: 290,
    tiny: 350,
    smallish: 420,
    small: 600,
    mid: 900,
    largeish: 1050,
    large: 1200,
    xlarge: 1400,
    xxlarge: 1800,
    huge: 2559,
  },
  spacing: 8,
  borderRadius: {
    large: 15,
    medium: 8,
    small: 5,
  },
  typography: {
    baseFontSize: 18,
    hColor: '#333',
    pColor: '#555',
    fontFamily: 'Manrope',
    styles: textStyles,
  },
  icons: {
    appStore: AppStore,
    googlePlay: GooglePlay,
    close: Close,
    mail: Mail,
    heart: Heart,
    instagram: Instagram,
    facebook: Facebook,
    bars: Bars,
  },
  presets: {
    something: {
      backgroundColor: 'red',
    },
    elevated: {
      shadowOffset: { width: 0, height: 0 },
      shadowColor: 'rgba(0, 0, 0, 0.5)',
      shadowRadius: 20,
      shadowOpacity: 0.3,
      elevation: 8,
    },
  },
  values: {
    ...getWindowDimensions(),
    maxHeaderMenuHeight: 60,
    headerHeight: 48,
    navBarHeight: 100,
    buttons: {
      small: {
        height: 20,
      },
      default: {
        height: 35,
      },
      large: {
        height: 60,
      },
    },
    zIndex: {
      header: 2,
      footer: 1,
      appStatusOverlay: 6,
    },
  },
  initialTheme: IS_SSR ? 'light' : window.___savedTheme || 'light',
} as const

const appTheme = createTheme(themeObj, {
  screenSize: () => useWindowSize(),
})

const styleGetter = (style: CSSObject) => {
  return style
}

type StyleGetter = typeof styleGetter

export const variantProvider = new VariantProvider<
  StyleGetter,
  typeof themeObj
>(appTheme, styleGetter)

export const Theme = variantProvider.theme

export type AppThemeModes = keyof typeof themeObj.colors
