import { React } from '@/app'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'

import { MyComponentComposition, MyComponentStyle } from '@/app/stylesheets/MyComponent'
import { Text, View } from '@/components'
export const MyComponent:React.FC<{
    styles?: StylesOf<MyComponentComposition>
} & ComponentVariants<typeof MyComponentStyle>> = ({ styles, variants }) => {
  const variantStyles = useDefaultComponentStyle<'u:MyComponent', typeof MyComponentStyle >('u:MyComponent', {
    styles,
    variants,
  })

  return <View style={variantStyles.wrapper}>
    <Text text={'My Component'} style={variantStyles.text}/>
  </View>
}
