import { Session } from '@/redux'
import { onMount } from '@codeleap/common'
import { useLocation } from '@reach/router'

const DEV_ORIGIN = 'https://cherry.codeleap.co.uk'

export const useApiURL = () => {
  const { origin } = useLocation()

  const isDEV = origin === DEV_ORIGIN

  onMount(() => {
    Session.setMode(isDEV)
  })
}

export * from './misc'
