/* eslint-disable max-len */
import { Theme, variantProvider, React } from '@/app'
import { AppStatusOverlay, Page, View, Link, Text, Icon, ActivityIndicator } from '@/components'
import { getRandomHomeImage, BackgroundDetails } from '@/utils'
import { onMount } from '@codeleap/common'
import { useState } from 'react'

export const Overlays = () => {
  return (
    <>
      <AppStatusOverlay />
      {/* <DebugModal/> */}
    </>
  )
}

const StoreButtons = (props) => (
  <View
    variants={['gap:2', 'marginVertical:2']}
    {...props}
  >
    <Link to={'https://apps.apple.com/us/app/cherry-personalised-dating/id1673810759'}>
      <Icon name={'appStore'} style={styles.linkButton} />
    </Link>
    <Link to={'https://play.google.com/store/apps/details?id=uk.co.codeleap.cherry&hl=en_US'}>
      <Icon name={'googlePlay'} />
    </Link>
  </View>
)

const HomeText = ({ messages }) => (

  <View
    css={styles.descriptionWrapper}
    variants={['column']}
    responsiveVariants={{
      mid: ['alignCenter', 'justifyCenter'],
    }}
  >
    {messages?.map((el, index) => <Text key={index} text={el?.text} css={{ lineHeight: 1.1, fontSize: 72, color: Theme.colors.light.white }} variants={['bold', 'white', 'uppercase']} />)}
    <StoreButtons down={'small'} />
    <Text
      variants={['h3', 'white', 'semiBold', 'marginVertical:2']}
      responsiveVariants={{
        mid: ['textCenter'],
      }}
    >
   Cherry is committed to creating a fun, safe, and honest environment to help as many people as possible find the love of their life or a great companion. Cherry prioritizes its members and values integrity above all.
    </Text>
    <StoreButtons up={'small'} />
  </View>
)

const IndexPage: React.FC = () => {
  const [homeImages, setHomeImages] = useState<BackgroundDetails | null>(null)

  onMount(() => {
    setHomeImages(getRandomHomeImage())
  })

  const messages = homeImages?.messages

  if (!homeImages) {
    return (
      <View css={styles.loadingWrapper} variants={['alignCenter', 'justifyCenter', 'fullWidth']}>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <Page
      title='Home'
      center={true}
      styles={{
        wrapper: [styles.wrapper, {
          backgroundImage: `url(${homeImages?.imageNameDesktop})`,
          [Theme.media.down('mid')]: {
            backgroundImage: `url(${homeImages?.imageNameMobile})`,
          },
        }],
      }}

    >
      <HomeText messages={messages} />
    </Page>
  )
}

export default IndexPage

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      minHeight: '100vh',
      width: '100vw',
      maxWidth: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      alignItems: 'flex-start',
      paddingTop: theme.spacing.value(16),
    },
    image: {},
    linkButton: {
      width: 150,
      height: 50,
    },
    imagePhone: {
      width: '60%',
      maxHeight: '80vh',
      objectFit: 'contain',
      opacity: 1,
      visibility: 'visible',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
      [Theme.media.down('small')]: {
        opacity: 0,
        visibility: 'hidden',
      },
    },
    descriptionWrapper: {
      zIndex: 9,
      width: '40%',
      [Theme.media.down('mid')]: {
        width: '100%',
      },
    },
    centerWrapper: {
      alignItems: 'flex-start',
      [Theme.media.down('mid')]: {
        flexDirection: 'column-reverse',
      },
    },
    boldText: {
      display: 'unset',
      ...Theme.spacing.marginHorizontal(0.8),
    },
    loadingWrapper: {
      height: '100vh',
    },
  }),
  true,
)
